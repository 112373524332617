import WebServiceRequest from '../Api/WebServiceRequest'

class CreateProductField extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Product/CreateProductField')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class GetProductFieldsByProductId extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductFieldsBy')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class DeleteProductField extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Product/DeleteProductField')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetProductFieldDetail extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductFieldDetailBy')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class UpdateProductFieldDetail extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Product/UpdateProductField')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class GetProductFieldFeaturesByFieldId extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductFeatureBy')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class CreateProductFieldFeature extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Product/CreateProductFeature')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}


// ---------------------------------------------------
class DeleteProductFieldFeature extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Product/DeleteProductFeature')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetFeatureDetail extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductFeatureByProductFeatureId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class GetProductFieldsByFeatureId extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductFeaturesFieldsByProductFeatureId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class UpdateProductFeature extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Product/UpdateProductFeature')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}
export {
  CreateProductField,
  GetProductFieldsByProductId,
  DeleteProductField,
  GetProductFieldDetail,
  UpdateProductFieldDetail,
  GetProductFieldFeaturesByFieldId,
  CreateProductFieldFeature,

  GetProductFieldsByFeatureId,
  GetFeatureDetail,
  UpdateProductFeature,
  DeleteProductFieldFeature
}
