<template>
  <b-overlay :show="showOverlay">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="createProduct" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Product Field Detail</h2>
        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Name"
              >

                <b-form-input
                    id="data-edit-seoTitle"
                    ref="fieldName"
                />
              </b-form-group>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Type Of Fields (this field cannot be updated later on)"
              >
                <v-select
                    v-model="fieldMode"
                    :options="fieldModes"
                    :reduce="name => name.value"
                    class="per-page-selector w-75 mx-50"
                    label="name"
                />

              </b-form-group>
            </b-col>
            <b-col class=" d-flex align-items-center" md="4">
              <b-form-group
                  label=" "
              >
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="optional"
                    name="checkbox-1"

                >
                  Optional
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  class=" "
                  variant="primary"
                  @click="createProductField"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { BButton, BCard, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BOverlay, BRow, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { CreateProductField } from '@/libs/Api/productField'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BForm,
    ValidationProvider,
    ckEditorMain,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BOverlay,
    vSelect,
  },
  name: 'create-product',
  title: 'create product',
  data() {
    return {

      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      fieldModes: [
        {
          name: 'drop down',
          value: 1
        },
        {
          name: 'radio button',
          value: 2
        },
        {
          name: 'check box',
          value: 3
        },
        {
          name: 'slider',
          value: 4
        },
      ],
      fieldMode: null,
      optional: false,
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: false
        },
        {
          text: `Manage Product`,
          href: `/apps/games/game-service-categories/game-service/products/manage-product?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: false
        },
        {
          text: `Create Product Field`,
          href: `/apps/games/game-service-categories/game-service/products/product-field/create-product-field?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: true
        },
      ],
      data: {
        name: '',
        productId: this.$route.query.productId,
        productFieldDetails: [],
      },
    }
  },

  async created() {
  },
  methods: {
    createPayload() {
      this.data.productFieldDetails.push({
        name: this.$refs.fieldName.$el.value,
        productFieldDetailType: this.fieldMode,
        optional: this.optional
      })
      return this.data
    },
    async createProductField() {
      let _this = this
      let data = _this.createPayload()

      if (data?.productFieldDetails[0]?.name !== '') {
        _this.showOverlay = true
        let createProductField = new CreateProductField(_this)
        createProductField.setParams(data)
        await createProductField.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push(`/apps/games/game-service-categories/game-service/products/manage-product?gameId=${_this.$route.query.gameId}&gameServiceCategoryId=${_this.$route.query.gameServiceCategoryId}&gameServiceId=${_this.$route.query.gameServiceId}&productId=${_this.$route.query.productId}`)
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'please fill in all the fields',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },

  }
}
</script>

<style scoped>

</style>
